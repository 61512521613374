<template>
  <div class="rank-index">
    <div
      class="
        consume-rank
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class="border-radius-6"
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/rankingStatistics/putCrowdRank"
          >投手实时排行</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/rankingStatistics/incomeRank"
          >收益排行</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/rankingStatistics/userRank"
          >用户排行</el-menu-item
        >
      </el-menu>
    </div>
    <!-- <router-view></router-view> -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "Rank",
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  methods: {
    handleTabClick(path) {},
  },
};
</script>
<style lang="scss" scoped>
.rank-index {
  .consume-rank {
    background: #fff;
    /deep/ .el-menu-item,
    .el-submenu__title {
      height: 34px;
    }
  }
  .el-menu--horizontal > .el-menu-item {
    height: 52px;
    line-height: 52px;
  }
  .consume-part {
    box-shadow: 0px 0px 6px 0px #f1f4f6;
  }
}
</style>
